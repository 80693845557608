@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,700;0,800;1,200;1,300;1,400;1,700;1,800&display=swap');

.relative {
  position: relative;
}

.center {
  display: flex;
  justify-content: center;
}

#dWx0cmEtY29udGVudC1odWI {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-direction: normal;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

/* iframe bug due to react-script */
/* https://stackoverflow.com/questions/69051008/react-injecting-iframe-with-max-z-index-on-reload-after-changes-development */
body > iframe[style*="2147483647"] {
  display: none;
}

#acm-footer h4,
#acm-footer a,
#acm-footer span,
#acm-footer__contact-us p,
#acm-footer__copyright {
  font-size: 0.875rem;
}